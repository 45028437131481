import * as React from "react";

import Page from "./../components/Layout/Page";
import Container from "../components/Layout/Container";
// markup
const InteriorPage = () => {
  return (
    <Page className="interiors">
      <section className="hero">
        <Container>
          <h1>
            <strong>Interior Design Services</strong>
            <span>in Aizawl, Mizoram</span>
          </h1>
        </Container>
      </section>
      <section className="intro">
        <Container>
          <p>
            We are an interior design consultancy based in Aizawl, Mizoram.
            White Walls has established a reputation for providing creative &
            functional design solutions for residential & commercial interior
            design projects.
          </p>
        </Container>
      </section>
      <section className="services">
        <Container>
          <h2>
            <strong>Interior Design Services that we provide</strong>
          </h2>
          <ul>
            <li>
              <span>Interior Space Planning</span>
            </li>
            <li>
              <span>Interior Decoration </span>
            </li>
            <li>
              <span>Project Management & Coordination </span>
            </li>
            <li>
              <span>Electrical Layout </span>
            </li>
            <li>
              <span>Ceiling Design </span>
            </li>
            <li>
              <span>Furniture Design & Selection </span>
            </li>
            <li>
              <span>Kitchen Design </span>
            </li>
            <li>
              <span>Showrooms & Stores </span>
            </li>
            <li>
              <span>Office Space Layout & Design </span>
            </li>
            <li>
              <span>Hardware Selection</span>
            </li>
          </ul>
        </Container>
      </section>
      <section className="contact-block">
        <Container>
          <h2>
            <strong>Talk to our designer</strong>
          </h2>
          <div>
            <a href="tel:+919654956742">
              <strong>Call Now</strong>
            </a>
          </div>
        </Container>
      </section>
    </Page>
  );
};

export default InteriorPage;
